<template>
    <div>
        <ul class="d-flex nav justify-content-end">
            <li class="nav-item" :class="{'active': index + 1 === activeTab}" v-for="(tab, index) in tabList" :key="index">
                <label :for="`${_uid}${index}`" v-text="tab" />
                <input
                    :id="`${_uid}${index}`"
                    type="radio"
                    :name="`${_uid}-tab`"
                    :value="index + 1"
                    v-model="activeTab"
                />
            </li>
        </ul>

        <template v-for="(tab, index) in tabList">
            <div class="tab-content" :key="index" v-if="index + 1 === activeTab">
              <slot :name="`tabPanel-${index + 1}`" />
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        tabList: {
            type: Array,
            required: true
        },
        variant: {
            type: String,
            required: false,
            default: () => "horizontal",
            validator: (value) => ["horizontal", "vertical"].includes(value)
        }
    },
    data() {
        return {
            activeTab: 1
        }
    }
}
</script>

<style scoped>
.nav {
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-item {
    border-bottom: 0px !important;
    border: #c2c2c2 solid 1px;
    border-right: none;
}

.nav-item label {
    display: block;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.nav-item:first-child {
    border-radius: 7px 0px 0px 0px;
}
.nav-item:last-child {
    border-radius: 0px 7px 0px 0px;
    border-bottom: none;
    border-right: #c2c2c2 solid 1px;
}
.nav-item input[type='radio'] {
    -webkit-appearance:none;
    display: none;
    outline:none;
}

.nav-item.active {
    color: #585858;
    background: #a4f4d1 ;
}

.tab-content {
    border: 1px solid #c2c2c2;
    border-radius: 7px 0px 7px 7px;
    padding: 30px;
}
</style>