<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                <li class="breadcrumb-item">
                  <a href="#" target="_self" class="">
                    <a href="#/" class="active"><i class="fas fa-home"></i> </a>
                  </a>
                </li>
                <li class="breadcrumb-item" style="display: inline-block">
                  <router-link class="active" :to="{ name: 'ListArticle' }"
                    >Danh sách article</router-link
                  >
                </li>
              </ol>
            </nav>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">{{ article.name }}</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <h4 class="mb-0">Generated Article</h4>
          <tabs :tabList="tabList">
            <template v-slot:tabPanel-1>
              <div v-html="article.spin"></div>
            </template>
            <template v-slot:tabPanel-2> {{ article.spin }} </template>
          </tabs>

          <h4 class="mb-0">Spintax</h4>
          <tabs :tabList="tabList">
            <template v-slot:tabPanel-1>
              <div v-html="article.spintax"></div>
            </template>
            <template v-slot:tabPanel-2> {{ article.spintax }} </template>
          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "@/views/Components/Tabs";

export default {
  name: "ArticleDetail",
  components: {
    Tabs,
  },
  data() {
    return {
      article: null,
      tabList: ["Text", "HTML"],
    };
  },
  created() {
    this.getArticle();
  },
  filters: {},
  methods: {
    async getArticle() {
      this.$preloaders.open();

      try {
        const data = await this.$store.dispatch(
          "articles/getArticle",
          this.$route.params.id
        );
        this.article = data.result;
      } catch (err) {
        console.log(err);
      }

      this.$preloaders.close();
    },
  },
};
</script>
